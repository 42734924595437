<template>
  <el-upload
    class="avatar-uploader"
    action="#"
    accept="image/*"
    :on-change="handleAvatarChange"
    :on-remove="removeImg"
    :auto-upload="false"
    list-type="picture-card"
    multiple
  >
    <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar" />
    <p v-else><img src="@/assets/addphoto.png" alt="" /></p> -->
    <i class="el-icon-plus"></i>
  </el-upload>
</template>

<script>
export default {
  // props: {
  //   fileList: {
  //     type: String,
  //     default: () => {},
  //   },
  // },
  data() {
    return {
      imageUrl: "",
    };
  },
  // computed: {
  //   imageUrl: {
  //     get() {
  //       return this.fileList;
  //     },
  //     set(val) {
  //       this.$emit("update:fileList", val);
  //     },
  //   },
  // },
  created() {},
  mounted() {},
  methods: {
    handleAvatarChange(file, fileList) {
      // console.log("file :>> ", file);
      this.imageUrl = URL.createObjectURL(file.raw);
      this.$emit("avatarFile", file, fileList);
    },
    removeImg(file, fileList) {
      this.$emit("removeFile", file, fileList);
    },
  },
};
</script>
<style lang="scss" scoped></style>
